var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{
    'mt-3 mb-1': _vm.$vuetify.breakpoint.smAndDown,
    'mt-10 mb-6': _vm.$vuetify.breakpoint.mdAndUp,
  }},[_c('div',{staticClass:"d-flex",class:{'flex-wrap': _vm.$vuetify.breakpoint.xsOnly}},[_c('img',{class:{
        'limit-height-14 mr-15': _vm.$vuetify.breakpoint.mdAndUp,
        'limit-height-10 mr-10': _vm.$vuetify.breakpoint.smOnly,
        'limit-height-10 mb-6 ml-auto mr-auto': _vm.$vuetify.breakpoint.xsOnly,
      },attrs:{"src":require('@/assets/images/cir_cii_stg_01.svg'),"alt":"Agrément crédit d'impôt Strataggem"}}),_c('div',[_c('base-subheading',[_vm._v(_vm._s(_vm.$t("pages_specific.main_page.tax_credit.title")))]),_c('div',{class:{
          'mt-5 mb-0': _vm.$vuetify.breakpoint.smAndDown,
          'mt-5 mb-7': _vm.$vuetify.breakpoint.mdAndUp,
          'pr-2': _vm.$vuetify.breakpoint.smAndUp,
        }},_vm._l((_vm.$t('pages_specific.main_page.tax_credit.p')),function(item,i){return _c('p',{key:i},[_vm._v(" "+_vm._s(item)+" ")])}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }